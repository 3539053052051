<template>
  <div>
    <my-nav-bar
      title="退货查找"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-sticky offset-top="1.22667rem">
        <van-search v-model="searchKey" placeholder="请输入商品名称" @search="onSearch" />
      </van-sticky>
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        ref="list"
        v-model="loading"
        class="list"
        :finished="finished"
        @load="handleSearchGoods"
      >
        <after-order
          v-for="(afterOrder, index) in list"
          :key="index"
          :order="afterOrder"
          @click="toDetailView(afterOrder)"
        />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { searchGoods } from '@/api/after_accept'
import AfterOrder from '../after-accept/components/order'
export default {
  name: 'AfterSearch',
  components: { myNavBar, AfterOrder },
  data() {
    return {
      searchKey: '',
      loading: false,
      finished: false,
      showEmpty: true,
      list: [],
      listQuery: {
        page: 0,
        name: ''
      }
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    // this.getAfterOrders()
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/after-accept-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    onSearch() {
      this.listQuery.name = this.searchKey
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.loading = false
      this.list = []
      this.$refs.list.check()
    },
    handleSearchGoods() {
      if (this.listQuery.name.trim() === '') {
        this.loading = false
        this.finished = true
        return
      }
      this.listQuery.page++
      searchGoods(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
        this.loading = false
      })
    },
    toDetailView(afterOrder) {
      this.$router.push({
        path: '/after-accept-detail',
        query: {
          line_id: afterOrder.line_id,
          order_id: afterOrder.id,
          is_finished: afterOrder.accept_finished_datetime ? 1 : 0,
          client_id: afterOrder.client_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
  padding-bottom: 0;
}
</style>
